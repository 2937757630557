// aggrid
import {Utils as _} from "ag-grid/main";
import {IHeaderParams, IHeader, Component,Column,Autowired,IMenuFactory,GridOptionsWrapper,SortController,LongTapEvent, TouchListener,IAfterGuiAttachedParams, IComponent,EventService,RefSelector,Events,ColumnApi,GridApi} from 'ag-grid/main'

export interface IHeaderComp2 extends IHeader, IComponent<IHeaderParams, IAfterGuiAttachedParams> {
    // empty
}

export class CtSearchSelectionListHeader extends Component implements IHeaderComp2 {

    private static TEMPLATE =
        '<div class="ag-cell-label-container" role="presentation">' +
        '  <span ref="eCollapse" class="ag-header-icon ag-header-cell-menu-button ag-header-cell-menu-button-ce ct-search-frame" aria-hidden="true"><span class="fa fa-compress fa-fixed"></span></span>' +
        '  <span ref="eExpand" class="ag-header-icon ag-header-cell-menu-button ag-header-cell-menu-button-ce ct-search-frame" aria-hidden="true"><span class="fa fa-expand fa-fixed"></span></span>' +
        '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button" aria-hidden="true"></span>' +
        '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
        '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
        '    <span ref="eTextSel" class="ag-header-cell-sel" role="columnheader">' +
        '      (<span ref="eTextSelTot" class="ag-header-cell-sel-tot" role="columnheader"></span><span ref="eTextSelCom" class="ag-header-cell-sel-com" role="columnheader"></span><span ref="eTextSelFil" class="ag-header-cell-sel-fil" role="columnheader"></span>)' +
        '    </span>' +
        //'    <span ref="eFilter" class="ag-header-icon ag-filter-icon" aria-hidden="true"></span>' +
        '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" aria-hidden="true"></span>' +
        '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" aria-hidden="true"></span>' +
        '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" aria-hidden="true"></span>' +
        '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" aria-hidden="true"></span>' +
        '  </div>' +
        '</div>';

    @Autowired('gridOptionsWrapper') private gridOptionsWrapper: GridOptionsWrapper;
    @Autowired('sortController') private sortController: SortController;
    @Autowired('menuFactory') private menuFactory: IMenuFactory;
    @Autowired('eventService') private eventService: EventService;

    //@RefSelector('eFilter') private eFilter: HTMLElement;
    @RefSelector('eSortAsc') private eSortAsc: HTMLElement;

    @RefSelector('eSortDesc') private eSortDesc: HTMLElement;
    @RefSelector('eSortNone') private eSortNone: HTMLElement;
    @RefSelector('eSortOrder') private eSortOrder: HTMLElement;
    @RefSelector('eMenu') private eMenu: HTMLElement;
    @RefSelector('eLabel') private eLabel: HTMLElement;
    @RefSelector('eText') private eText: HTMLElement;
    @RefSelector('eTextSel') private eTextSel: HTMLElement;
    @RefSelector('eTextSelTot') private eTextSelTot: HTMLElement;
    @RefSelector('eTextSelFil') private eTextSelFil: HTMLElement;
    @RefSelector('eTextSelCom') private eTextSelCom: HTMLElement;
    @RefSelector('eCollapse') private eCollapse: HTMLElement;
    @RefSelector('eExpand') private eExpand: HTMLElement;

    private params:IHeaderParams;

    constructor() {
        super(CtSearchSelectionListHeader.TEMPLATE);
    }

    public init(params: IHeaderParams): void {
        this.params = params;
        let aparams:any = <any> params;
        aparams.object.instance = this;
        this.setupTap();
        this.setupIcons(params.column);
        this.setupMenu();
        this.setupCollapse(aparams.tree);
        this.setupExpand(aparams.tree);
        this.setupSort();
        this.setupText(params.displayName);
    }

    private setupText(displayName: string): void {
        this.eText.innerHTML = displayName;
    }

    private setupIcons(column:Column): void {
        this.addInIcon('sortAscending', this.eSortAsc, column);
        this.addInIcon('sortDescending', this.eSortDesc, column);
        this.addInIcon('sortUnSort', this.eSortNone, column);
        this.addInIcon('menu', this.eMenu, column);
        //this.addInIcon('filter', this.eFilter, column);
    }

    private addInIcon(iconName: string, eParent: HTMLElement, column: Column): void {
        let eIcon = _.createIconNoSpan(iconName, this.gridOptionsWrapper, column);
        eParent.appendChild(eIcon);
    }

    private setupTap(): void {
        if (this.gridOptionsWrapper.isSuppressTouch()) { return; }

        let touchListener = new TouchListener(this.getGui());

        if (this.params.enableMenu) {
            let longTapListener = (event: LongTapEvent)=> {
                this.gridOptionsWrapper.getApi().showColumnMenuAfterMouseClick(this.params.column, event.touchStart);
            };
            this.addDestroyableEventListener(touchListener, TouchListener.EVENT_LONG_TAP, longTapListener);
        }

        if (this.params.enableSorting) {
            let tapListener = ()=> {
                this.sortController.progressSort(this.params.column, false);
            };

            this.addDestroyableEventListener(touchListener, TouchListener.EVENT_TAP, tapListener);
        }

        this.addDestroyFunc( ()=> touchListener.destroy() );
    }

    private setupMenu(): void {

        // if no menu provided in template, do nothing
        if (!this.eMenu) {
            return;
        }

        if (!this.params.enableMenu) {
            _.removeFromParent(this.eMenu);
            return;
        }

        this.eMenu.addEventListener('click', ()=> this.showMenu(this.eMenu));

        if (!this.gridOptionsWrapper.isSuppressMenuHide()) {
            this.eMenu.style.opacity = '0';
            this.addGuiEventListener('mouseover', ()=> {
                this.eMenu.style.opacity = '1';
            });
            this.addGuiEventListener('mouseout', ()=> {
                this.eMenu.style.opacity = '0';
            });
        }
        let style = <any> this.eMenu.style;
        style['transition'] = 'opacity 0.2s, border 0.2s';
        style['-webkit-transition'] = 'opacity 0.2s, border 0.2s';
    }

    public showMenu(eventSource: HTMLElement) {
        this.menuFactory.showMenuAfterButtonClick(this.params.column, eventSource);
    }

    private setupCollapse(tree:boolean): void {
        if (!this.eCollapse) {
            return;
        }
        _.addOrRemoveCssClass(this.eCollapse, 'ag-hidden', !tree);
        this.eCollapse.addEventListener('click', ()=> {
            this.gridOptionsWrapper.getApi().collapseAll();
        });
    }

    private setupExpand(tree:boolean): void {
        if (!this.eExpand) {
            return;
        }
        _.addOrRemoveCssClass(this.eExpand, 'ag-hidden', !tree);
        this.eExpand.addEventListener('click', ()=> {
            this.gridOptionsWrapper.getApi().expandAll();
        });
    }

    private removeSortIcons(): void {
        _.removeFromParent(this.eSortAsc);
        _.removeFromParent(this.eSortDesc);
        _.removeFromParent(this.eSortNone);
        _.removeFromParent(this.eSortOrder);
    }

    public setupSort(): void {
        let enableSorting = this.params.enableSorting;

        if (!enableSorting) {
            this.removeSortIcons();
            return;
        }

        // add the event on the header, so when clicked, we do sorting
        if (this.eLabel) {
            this.addDestroyableEventListener(this.eLabel, 'click', (event:MouseEvent) => {
                this.params.progressSort(event.shiftKey);
            });
        }

        this.addDestroyableEventListener(this.params.column, Column.EVENT_SORT_CHANGED, this.onSortChanged.bind(this));
        this.onSortChanged();

        this.addDestroyableEventListener(this.eventService, Events.EVENT_SORT_CHANGED, this.setMultiSortOrder.bind(this));
        this.setMultiSortOrder();
    }

    private onSortChanged(): void {

        _.addOrRemoveCssClass(this.getGui(), 'ag-header-cell-sorted-asc', this.params.column.isSortAscending());
        _.addOrRemoveCssClass(this.getGui(), 'ag-header-cell-sorted-desc', this.params.column.isSortDescending());
        _.addOrRemoveCssClass(this.getGui(), 'ag-header-cell-sorted-none', this.params.column.isSortNone());

        if (this.eSortAsc) {
            _.addOrRemoveCssClass(this.eSortAsc, 'ag-hidden', !this.params.column.isSortAscending());
        }

        if (this.eSortDesc) {
            _.addOrRemoveCssClass(this.eSortDesc, 'ag-hidden', !this.params.column.isSortDescending());
        }

        if (this.eSortNone) {
            let alwaysHideNoSort = !this.params.column.getColDef().unSortIcon && !this.gridOptionsWrapper.isUnSortIcon();
            _.addOrRemoveCssClass(this.eSortNone, 'ag-hidden', alwaysHideNoSort || !this.params.column.isSortNone());
        }
    }

    // we listen here for global sort events, NOT column sort events, as we want to do this
    // when sorting has been set on all column (if we listened just for our col (where we
    // set the asc / desc icons) then it's possible other cols are yet to get their sorting state.
    private setMultiSortOrder(): void {

        if (!this.eSortOrder) {
            return;
        }

        let col = this.params.column;
        let allColumnsWithSorting = this.sortController.getColumnsWithSortingOrdered();
        let indexThisCol = allColumnsWithSorting.indexOf(col);
        let moreThanOneColSorting = allColumnsWithSorting.length > 1;
        let showIndex = col.isSorting() && moreThanOneColSorting;

        _.setVisible(this.eSortOrder, showIndex);

        if (indexThisCol>=0) {
            this.eSortOrder.innerHTML = (indexThisCol+1).toString();
        } else {
            this.eSortOrder.innerHTML = '';
        }
    }

    public updateSelection(s:any) {
        if (this.eTextSel) {
            _.addOrRemoveCssClass(this.eTextSel, 'ag-hidden', s.total == 0);
        }
        if (this.eTextSelTot) {
            _.addOrRemoveCssClass(this.eTextSelTot, 'ag-hidden', s.total == 0);
            this.eTextSelTot.innerHTML = s.total > 0 ? s.total : '';
        }
        if (this.eTextSelFil) {
            _.addOrRemoveCssClass(this.eTextSelFil, 'ag-hidden', s.filtered == 0);
            this.eTextSelFil.innerHTML = s.filtered > 0 ? (s.filtered + ' hidden by filter') : '';
        }
        if (this.eTextSelCom) {
            this.eTextSelCom.innerHTML = s.filtered > 0 ? ' / ' : '';
        }
    }

}
