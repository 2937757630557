/**
 * Created by joerg on 7/17/17.
 */

// rxjs
// rxjs
import 'rxjs/Rx';
import {Subscription,Subject,BehaviorSubject} from 'rxjs/Rx';


// angular
import {Injectable, Component,Input,HostListener,Output,EventEmitter,Optional} from '@angular/core';

// mn
import {MnDeepMerge, MnUnsubscribe} from "@mn/core"

//
import {CtSearchService} from './CtSearchService';

export type CtSearchNodeParams = { [name: string]: string[] };

export interface CtSearchNodeMeta {
    url: string;
    propagate?: string; // term under which to propagate results as params to children
    startfrom?: boolean;
    terms: string[];
    result: string[];
    title: string;
    holder: any;
    key?: string;
}

export class CtSearchNode {

    private mParent: CtSearchNode;
    protected mParams:CtSearchNodeParams = {};
    protected mChildren: CtSearchNode[] = [];
    protected mMeta: CtSearchNodeMeta;
    protected mValid:boolean = false;

    constructor() {}

    public setValue(value:any) {
        let key = this.mMeta.key || 'standard';
        this.mMeta.holder[key] = value;
    }

    public getValue():any {
        let key = this.mMeta.key || 'standard';
        return this.mMeta.holder[key];
    }

    public params() {
        return this.mParams;
    }

    public setParentNode(parent:CtSearchNode) {
        if (this.mParent) {
            if (this.mParent === parent) return;
            this.mParent.removeChildNode(this);
        }
        this.mParent = parent;
        parent.addChildNode(this);
    }

    public getParentNode() {
        return this.mParent;
    }

    public addChildNode(child:CtSearchNode) {
        if (this.mChildren.indexOf(child) >= 0) return;
        this.mChildren.push(child);
    }

    public removeChildNode(child:CtSearchNode) {
        this.mChildren.splice(this.mChildren.indexOf(child),1);
    }

    public setNodeMeta(meta:CtSearchNodeMeta) {
        this.mMeta = meta;
    }

    public validateNode(params:CtSearchNodeParams) {
        this.mValid = true;
        this.mParams = params;
        this.validateChildNodes(params);
    }

    public validateChildNodes(parent_params:CtSearchNodeParams) {
        var child_params = parent_params;
        if (this.mMeta && this.mMeta.propagate) {
            var my_params = {};
            //my_params[this.mMeta.propagate] = this.mMeta.result;
            my_params[this.mMeta.propagate] = this.getValue().slice();
            child_params = MnDeepMerge.merge(parent_params, my_params);
        }
        for (var i = 0, l = this.mChildren.length; i < l; i++) {
            this.mChildren[i].validateNode(child_params);
        }
    }

    public invalidateNode() {
        this.mValid = false;
        this.invalidateChildNodes();
    }

    public invalidateChildNodes() {
        for (var i = 0, l = this.mChildren.length; i < l; i++) {
            this.mChildren[i].invalidateNode();
        }
    }

    onDestroy() {
        if (this.mParent) this.mParent.removeChildNode(this);
        this.mParent = null;
    }

}
