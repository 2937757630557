/**
 * Created by joerg on 7/17/17.
 */

// angular
import {Component,Output,EventEmitter,ViewChild,Input} from '@angular/core';

// mn
import {MnDatasetFactory} from './MnDatasetFactory';
import {MnFileUploadService} from "@mn/file";
import {MnBackend} from "@mn/core";

//
import {CtSearchService} from './CtSearchService';
import {CtSearchNode} from './CtSearchNode';
import {CtSearchSelection} from "./CtSearchSelection";
import {CtSearchSelectionSiblings} from "./CtSearchSelectionSiblings";
import {CtSearchReg} from "./CtSearchReg";
import {CtSearchToxModal} from "./CtSearchToxModal";
import {CtSearchChem} from "./CtSearchChem";

@Component({
    selector: 'ct-search',
    templateUrl: './CtSearch.html',
    styles: [
        `
            /*:host:hover {
                color: red;
            }*/
        `
    ],
    providers: [MnFileUploadService],
    host: {
        '[class]' : 'mTheme'
    }
})
export class CtSearch {

    mTheme:string;
    mDatabaseSelectionMeta = {
        url: '/chemtunes/dir/databases/',
        propagate: 'db',
        result: ['ChemTunes'],
        terms:[],
        title: 'Databases',
        holder: this.mSearchService.Query,
        key: 'databases'
    };
    private mDatasetUrl:any;

    @Input() public button:boolean = true;
    @Input() public header:boolean = false;

    @Output('search') mSearch = new EventEmitter();
    @Input() public testhook:Function;

    @ViewChild('chem') private mChemSelection:CtSearchChem;
    @ViewChild('database_selection') private mDatabaseSelection:CtSearchSelection;
    @ViewChild('regulatory_selection') private mRegulatorySelection:CtSearchReg;
    @ViewChild('tox_selection') private mToxSelection:CtSearchToxModal;

    constructor(private mSearchService:CtSearchService, private mDsFac:MnDatasetFactory, private mBackend:MnBackend) {
        this.mTheme = this.mSearchService.theme();
        this.mDatasetUrl = ()=> {
            //return '/test/';
            if (mSearchService.Query.chem.datasetid && mSearchService.Query.chem.datasetid.length > 0) {
                console.log("using existing dataset",mSearchService.Query.chem);
                return '/datasets/dataset/' + mSearchService.Query.chem.datasetid + '/append/'
            } else {
                console.log("creating new dataset");
                return this.mBackend.post('/datasets/dataset/',{context:"chemtunes"})
                    .map(res => res.json())
                    .map((dataset) => {
                        console.log("new dataset created",dataset.id);
                        mSearchService.Query.chem.datasetid = dataset.id;
                        return '/datasets/dataset/' + mSearchService.Query.chem.datasetid + '/append/'
                    })
                    .toPromise()
            }
        }
    }

    ngAfterViewInit() {
        //this.parent = this.mDatabaseSelection;
        this.mDatabaseSelection.setNodeMeta(this.mDatabaseSelectionMeta);
        this.mRegulatorySelection.setParentNode(this.mDatabaseSelection);
        this.mToxSelection.setParentNode(this.mDatabaseSelection);
        this.mDatabaseSelection.validateNode({});
    }

    private emptyRemove(obj) {
        for (var key in obj) {
            if(!obj.hasOwnProperty(key)) continue;
            let v = obj[key];
            if (v.constructor == Array) {
                for (var i = 0, l = v.length; i < l; i++) {
                    if (this.emptyRemove(v[i]) == false) {
                        return false;
                    }
                }
            } else {
                if (v.standard && v.standard.length == 0) {
                    delete obj[key];
                }
            }
        }
    }

    private onSearch($event) {
        console.log("Search");
        let query = this.mSearchService.Query;
        if (this.testhook) {
            query = this.testhook(query);
        }
        this.emptyRemove(query.reg);
        for (var i = 0, l = query.tox.study.length; i < l; i++) {
            this.emptyRemove(query.tox.study[i]);
        }
        console.log("SearchSearchSearchSearchSearchSearchSearchSearchSearchSearchSearchSearchSearchSearchSearchSearchSearchSearchSearch",query);
        /*this.mDsFac.createDatasetIdFromQuery(query).subscribe((data) => {
            console.log("query",data);
            this.mSearch.emit(data);
        });*/
    }

    private onClearAll() {
        this.onClearChemistry();
        this.onClearRegulatory();
        this.onClearToxicology();
    }
    private onClearChemistry() {
        this.mSearchService.Query.chem.datasetid = "";
        this.mSearchService.Query.chem.terms = [];
        this.mChemSelection.clearTerms();
    }
    private onClearRegulatory() {
        this.mSearchService.Query.reg = {active: this.mSearchService.Query.reg.active};
        this.mRegulatorySelection.invalidateNode();
        this.mRegulatorySelection.validateNode(this.mRegulatorySelection.params());
    }
    private onClearToxicology() {
        this.mSearchService.Query.tox.study = [];
    }

    private emptySearch():boolean {
        let chem_empty =
            (this.mSearchService.Query.chem.active == false) ||
            (this.mSearchService.Query.chem.mode == 'term' && this.mSearchService.Query.chem.terms.length == 0) ||
            (this.mSearchService.Query.chem.mode == 'structure' && this.mSearchService.Query.chem.datasetid == "");
        let reg_empty =
            (this.mSearchService.Query.reg.active == false) ||
            (this.emptyStudyRecurse(this.mSearchService.Query.reg));
        let tox_empty =
            (this.mSearchService.Query.tox.active == false) ||
            (this.mSearchService.Query.tox.study.length == 0);
        return chem_empty && reg_empty && tox_empty;
    }

    private emptyStudyRecurse(obj):boolean {
        for (var key in obj) {
            if(!obj.hasOwnProperty(key)) continue;
            let v = obj[key];
            if (v.constructor == Array) {
                for (var i = 0, l = v.length; i < l; i++) {
                    if (this.emptyStudyRecurse(v[i]) == false) {
                        return false;
                    }
                }
            } else {
                if (v.standard && v.standard.length > 0) {
                    return false;
                }
            }
        }
        return true;
    }

    private onUploadDone() {
        this.mChemSelection.reloadDataset();
    }

    /*public invalidate() {
         console.log("Invalidate CtSearch");
         this.mSearchService.getSearchRows('/chemtunes/reg/attribute/?db=ChemTunes',(rows)=>{
            this.mRegInterfaces = rows.map((r)=>{
                return {
                    url: `/chemtunes/reg/attribute/${r.domain}/${r.context}/${r.name}/?db=ChemTunes`,
                    title: r.name,
                    selection: [],
                }
            });
            this.mRegulatorySelection.setInterfaces(this.mRegInterfaces);
         });
         super.invalidate();
     }*/

}
