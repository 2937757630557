/**
 * Created by joerg on 5/3/17.
 */

// angular
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

// uirouter
import { UIRouterModule } from '@uirouter/angular';

// mn
import { MnCoreModule } from '@mn/core';
import { MnRouteModule } from '@mn/route';
import { MnAuthModule  } from '@mn/auth'
import { MnFileModule  } from '@mn/file'
import { MnGridModule } from "@mn/grid";
import { MnMaterialModule  } from '@mn/material'
import { MnJsmeModule  } from '@mn/jsme'

// material -- to be moved
import {
    MatRadioModule,
} from '@angular/material';

// ngxuploader
//import { NgUploaderModule } from './MnFileUploadExternalApi';

//
import { CtSearchService } from './CtSearchService';
import { CtSearch } from './CtSearch';
import { CtSearchChem } from './CtSearchChem';
import { CtSearchReg } from './CtSearchReg';
import { CtSearchSplitter } from './CtSearchSplitter';
import { CtSearchSelection } from './CtSearchSelection';
import { CtSearchSelectionList } from './CtSearchSelectionList';
import { CtSearchSelectionSiblings } from './CtSearchSelectionSiblings';
import { CtSearchTox,CtSearchToxGroup } from './CtSearchTox';
import { CtSearchToxModal,CtSearchToxModalService } from './CtSearchToxModal';
import { CtSearchToxModalController } from './CtSearchToxModalController';
import { CtSearchTest } from './CtSearchTest';
import { MnDatasetFactory } from './MnDatasetFactory'
import { MnTextareaAutosize } from './MnTextareaAutosize'
import { MnDatasetGrid,MnDatasetGridCell,MnDatasetGridCellImage } from './MnDatasetGrid'
import { MnJsmeDialog,MnJsmeDialogService } from './MnJsmeDialog'

import {VirtualRowComponent,ComponentFactoryResolverService} from './vs/api';
import {VirtualScrollComponent} from './vs/virtualScroll.component';

//import {MnFileUploadInput, MnFileUploadDrop} from './MnFileUploadDirectives'

let directives = [
    CtSearch,
    CtSearchSplitter,
    CtSearchSelection,
    CtSearchSelectionList,
    CtSearchSelectionSiblings,
    CtSearchChem,
    CtSearchReg,
    CtSearchTox,CtSearchToxGroup,
    CtSearchToxModal,
    CtSearchToxModalController,
    MnDatasetGrid,
    MnDatasetGridCell,
    MnJsmeDialog,
    CtSearchTest,
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        UIRouterModule.forChild(),
        MnCoreModule,
        MnRouteModule,
        MnAuthModule,
        MnFileModule,
        MnGridModule,
        MnMaterialModule,
        MatRadioModule,
        MnJsmeModule,
        //NgUploaderModule
    ],
    exports: [CtSearch],
    declarations: [...directives, MnTextareaAutosize,VirtualRowComponent, VirtualScrollComponent,MnDatasetGridCellImage/*, MnFileUploadInput, MnFileUploadDrop*/],
    entryComponents: [VirtualRowComponent,...directives],
    providers: [CtSearchService,CtSearchToxModalService,ComponentFactoryResolverService,MnJsmeDialogService,MnDatasetFactory]
})
export class CtSearchModule {
    constructor() {}
    /*static forRoot(): ModuleWithProviders {
        return {
            ngModule: MnHelpModule,
            providers: [MnHelpService]
        };
    }*/
}

export class ct_search {
    static configure():any {
        return [
            CtSearchModule
            /*MnHelpModule.forRoot()*/
        ];
    }
}