/**
 * Created by joerg on 7/17/17.
 */

// rxjs
import 'rxjs/Rx';
import {Observable,Subject, Subscription} from 'rxjs/Rx';

// angular
import {Component,ViewChild,Inject,Injectable} from '@angular/core';
import {MatDialogRef,MatDialog,MatDialogConfig,MAT_DIALOG_DATA} from '@angular/material';

//
import {CtSearchService} from './CtSearchService';
import {CtSearchNode,CtSearchNodeParams,CtSearchNodeMeta} from './CtSearchNode';
import {CtSearchSelection} from "./CtSearchSelection";
import {CtSearchToxModalController} from "./CtSearchToxModalController";

@Component({
    selector: 'ct-search-tox-modal',
    templateUrl: './CtSearchToxModal.html',
    styles: [
        `
            .layout-fix {
                height: 100%; 
                width: 100%; 
                position: relative;
            }   
        `
    ],
    host: {
        '[class]' : 'mTheme'
    }
})
export class CtSearchToxModal extends CtSearchNode{

    mTheme:string;
    mStudy:any = {};

    @ViewChild('study_type_selection') private mStudyTypeSelection:CtSearchSelection;
    @ViewChild('controller') private mController:CtSearchToxModalController;

    constructor(private mSearchService:CtSearchService, public dialogRef: MatDialogRef<CtSearchToxModal>, @Inject(MAT_DIALOG_DATA) data: any) {
        super();
        this.mStudy = data;
        this.mTheme = this.mSearchService.theme();
    }

    ngAfterViewInit() {
        this.mStudyTypeSelection.setParentNode(this);
        this.mController.setParentNode(this.mStudyTypeSelection);
        this.mController.setStudy(this.mStudy);
        if (this.mValid) {
            this.metaStudyTypes();
            super.validateNode(this.mParams);
        }
    }

    private emptyStudy():boolean {
        return this.emptyStudyRecurse(this.mStudy);
    }

    private emptyStudyRecurse(obj):boolean {

        for (var key in obj) {
            if(!obj.hasOwnProperty(key)) continue;
            let v = obj[key];
            if (v.constructor == Array) {
                for (var i = 0, l = v.length; i < l; i++) {
                    if (this.emptyStudyRecurse(v[i]) == false) {
                        return false;
                    }
                }
            } else {
                if (v.standard && v.standard.length > 0) {
                    return false;
                }
            }
        }
        return true;
    }


    private onReset($event) {
        this.invalidateNode();
        this.validateNode(this.mParams);
    }

    public validateNode(params:CtSearchNodeParams) {
        console.log("CtSearchToxModal:validateNode",params);
        this.mParams = params;
        if (this.mStudyTypeSelection) {
            this.metaStudyTypes();
            super.validateNode(params);
        }
     }

    public metaStudyTypes() {
        this.mStudyTypeSelection.setNodeMeta({
            url: '/chemtunes/terms/study-types/',
            propagate: 'context',
            result: [],
            title: "study.type",
            terms: [],
            holder: this.mSearchService.getTermsFromObject(this.mStudy,'type')
        });
    }

    /*public invalidateNode() {
        super.invalidateNode();
        this.mRegulatorySelection.setNodeMetas([]);
    }*/

    ngOnDestroy() {
        this.onDestroy()
    }

}


@Injectable()
export class CtSearchToxModalService {

    constructor(public dialog: MatDialog) { }

    private mChange = new Subject();

    subscribe(next) {
        return this.mChange.subscribe(next);
    }
    next(event){
        this.mChange.next(event);
    }

    public open(parent,study:any): Observable<boolean> {
        let dialogRef: MatDialogRef<CtSearchToxModal>;
        dialogRef = this.dialog.open(CtSearchToxModal,
            {
                width: "80vw",
                height: "90%",
                data: study,
                panelClass: "ct-search-theme-default"
            }
        );
        dialogRef.componentInstance.setParentNode(parent);
        dialogRef.componentInstance.validateNode(parent.params());
        //dialogRef.componentInstance.show(study, i);

        return dialogRef.afterClosed();
    }
}

