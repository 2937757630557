/**
 * Created by joerg on 7/17/17.
 */

// rxjs
import 'rxjs/Rx';
import {Subscription} from 'rxjs/Rx';

// angular
import {Component,ViewChild} from '@angular/core';

// mn
import {MnBackend, MnUnsubscribe} from "@mn/core"

//
import {CtSearchService} from './CtSearchService';
import {MnDatasetGrid} from "./MnDatasetGrid";

@Component({
    selector: 'ct-search-chem',
    templateUrl: './CtSearchChem.html',
    /*styles: [
        `
            /!*:host:hover {
                color: red;
            }*!/
        `,
        `
            :host {
                position: relative;
            }
            
            /deep/ .mat-radio-container .mat-ripple {
                display: none !important;
            }

            /deep/ .mat-radio-container {
                height: 16px !important;
                width: 16px !important;
            }

            /deep/ .mat-radio-inner-circle {
                height: 16px !important;
                width: 16px !important;
            }

            /deep/ .mat-radio-outer-circle {
                height: 16px !important;
                width: 16px !important;
                border-width: 1px !important;
            }

            /deep/ .mat-radio-label-content {
                padding-left: 6px !important;
            }


        .round-button {
	        font-size: 1em;
	        line-height: 1.0em;
	        color: darkgrey;
	        background: transparent;
	        margin: auto;
	        padding: 2px 8px;
	        border-radius: 500px;
	        border: 1px solid darkgrey;
	        position: relative;
	        cursor: pointer;
        }
        .round-button[checked] {
	        color: #ffffff;
	        background: darkgrey;
        }
        .round-button:enabled:hover::after {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            content: '';
            background-color: rgba(0,0,0, 0.12);
            border-radius: inherit;
            pointer-events: none;
        }
        .round-button[checked]:enabled:hover::after {
            background-color: rgba(255,255,255, 0.22);
        }
        .round-button:enabled:active::after {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            content: '';
            background-color: rgba(0,0,0, 0.22);
            border-radius: inherit;
            pointer-events: none;
        }
        .round-button[checked]:enabled:active::after {
            background-color: rgba(255,255,255, 0.42);
        }
        .round-button:disabled {
        	color: #ccc;
	        background-color: #eee;
	        border-color: #ccc;
        }
        .round-button[checked]:disabled {
        	color: #fff;
	        background-color: #ddd;
	        border-color: #ddd;
        }
        .dropdown-menu{
            position: absolute;
            z-index: 999;
            background-color: white;
            border: 1px solid;
            border-color: silver;
            list-style: none;
            margin-top: 1px;
            text-align: right;
            margin-left: 55px;
            /!* padding-left: 0px; *!/
            padding: 10px;
            line-height: 25px;
            font-weight: bold;
            width: 100px;
        }
        .dropdown-menu li{
            cursor: pointer;
        }
        .show{
            display: block;
        }
        .hide{
            display: none;
        }
        `
    ]*/
    styles: [
        `
        button {
	        font-size: 1em;
	        line-height: 1.0em;
	        color: darkgrey;
	        background: transparent;
	        margin: auto;
	        padding: 2px 8px;
	        border-radius: 500px;
	        border: 1px solid darkgrey;
	        position: relative;
	        cursor: pointer;
        }
        button[checked] {
	        color: #ffffff;
	        background: darkgrey;
        }
        button:enabled:hover::after {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            content: '';
            background-color: rgba(0,0,0, 0.12);
            border-radius: inherit;
            pointer-events: none;
        }
        button[checked]:enabled:hover::after {
            background-color: rgba(255,255,255, 0.22);
        }
        button:enabled:active::after {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            content: '';
            background-color: rgba(0,0,0, 0.22);
            border-radius: inherit;
            pointer-events: none;
        }
        button[checked]:enabled:active::after {
            background-color: rgba(255,255,255, 0.42);
        }
        button:disabled {
        	color: #ccc;
	        background-color: #eee;
	        border-color: #ccc;
        }
        button[checked]:disabled {
        	color: #fff;
	        background-color: #ddd;
	        border-color: #ddd;
        }
        .dropdown-menu{
            position: absolute;
            z-index: 999;
            background-color: white;
            border: 1px solid;
            border-color: silver;
            list-style: none;
            margin-top: 1px;
            text-align: right;
            margin-left: 115px;
            /* padding-left: 0px; */
            padding: 10px;
            line-height: 25px;
            font-weight: bold;
            width: 100px;
        }
        .dropdown-menu li{
            cursor: pointer;
        }
        .show{
            display: block;
        }
        .hide{
            display: none;
        }
        `
    ]
})
@MnUnsubscribe()
export class CtSearchChem {


    private mChemTerms:string = "";
    private mOnChemTermsChange:Subscription;
    private mDatasetUrl:any;

    @ViewChild('datasetgrid') private mDatasetGrid:MnDatasetGrid;

    constructor(private mSearchService:CtSearchService, private mBackend:MnBackend) {
        this.mOnChemTermsChange = this.mSearchService.onChemTermsChange();
        this.onChemTermsChange(this.mSearchService.Query.chem.terms);
        this.mDatasetUrl = ()=> {
            //return '/test/';
            if (mSearchService.Query.chem.datasetid && mSearchService.Query.chem.datasetid.length > 0) {
                console.log("using existing dataset",mSearchService.Query.chem);
                return '/datasets/dataset/' + mSearchService.Query.chem.datasetid + '/append/'
            } else {
                console.log("creating new dataset");
                return this.mBackend.post('/datasets/dataset/',{context:"chemtunes"})
                    .map(res => res.json())
                    .map((dataset) => {
                        console.log("new dataset created",dataset.id);
                        mSearchService.Query.chem.datasetid = dataset.id;
                        return '/datasets/dataset/' + mSearchService.Query.chem.datasetid + '/append/'
                    })
                    .toPromise()
            }
        }

    }

    ngAfterViewInit() {
        //this.mRegulatorySelection.setParentNode(this);
    }

    isSimilarDropdownOpen = false;
    _selectedSimilarVal = '';
    onChangeOption($event, data){
        this.mSearchService.Query.chem.structure.key = data.key;
        if(data.value){
            this._selectedSimilarVal = data.value;
            this.mSearchService.Query.chem.structure.value = data.value;
        }
        this.isSimilarDropdownOpen = false;
    }

    private onChemTermsChange(chem_terms:string[]) {
        let chem_terms_string = chem_terms.join('\n');
        if (chem_terms_string != this.mChemTerms) {
            this.mChemTerms = chem_terms_string;
        }
    }

    clearTerms(){
        this.mChemTerms = '';
    }

    public reloadDataset() {
        if (this.mDatasetGrid) {
            this.mDatasetGrid.reload();
        }
    }

    private chemTermsChange(chem_terms_string) {
        console.log(chem_terms_string);
        this.mSearchService.setChemTerms(chem_terms_string.split('\n'));
        console.log(this.mSearchService.Query.chem.terms);
    }

    ngOnDestroy() {
        // nothing
    }

}


