/**
 * Created by Joerg on 09.10.2017.
 */

// rxjs
import 'rxjs/Rx';
import {Subscription,Subject,BehaviorSubject} from 'rxjs/Rx';

// angular
import {Component,ViewChildren,QueryList} from '@angular/core';

//
import {CtSearchService} from './CtSearchService';
import {CtSearchNode,CtSearchNodeParams,CtSearchNodeMeta} from './CtSearchNode';
import {CtSearchSelection} from "./CtSearchSelection";

@Component({
    selector: 'ct-search-tox-modal-controller',
    templateUrl: './CtSearchToxModalController.html',
    styles: [
        `
            /*:host:hover {
                color: red;
            }*/
            /deep/ .mat-tab-body-wrapper {height: 100%;}
        `
    ]
})
export class CtSearchToxModalController extends CtSearchNode{

    mStudy:any = {};
    mQParams:any = {};
    private mSelectionsChange:Subscription;
    private mStudyQueryParametersChange:Subscription;

    @ViewChildren(CtSearchSelection) private mSelections:QueryList<CtSearchSelection>;

    constructor(private mSearchService:CtSearchService) {
        super();
    }

    public setStudy(study:any) {
        this.mStudy = study;
    }

    ngAfterViewInit() {
        this.mSelectionsChange = this.mSelections.changes.subscribe(() => this.onSelectionsChange());
    }

    private onSelectionsChange() {
        this.updateNodeMetas();
    }

    private keysEqual(a:any, b:any):boolean {
        var aKeys = Object.keys(a).sort();
        var bKeys = Object.keys(b).sort();
        return JSON.stringify(aKeys) === JSON.stringify(bKeys);
    }

    private longestPath(parents:string[]):string[] {
        var result = [];
        var last = [];
        for (var i = 0, l = parents.length; i < l;i++) {
            var p = parents[i];
            if (this.mQParams[p] &&  this.mQParams[p].depends) {
                var d = this.mQParams[p].depends;
                if (d.length == 1) {
                    if (last.length < 1) {
                        last = [d[0]];
                        if (last.length >= result.length) {
                            result = [p].concat(last);
                        }
                    }
                } else if (d.length > 1) {
                    var r = this.longestPath(d);
                    if (last.length < r.length) {
                        last = r;
                        if (last.length >= result.length) {
                            result = [p].concat(last);
                        }
                    }
                }
            }
        }
        return result;
    }

    public getTermsFromObject(obj,key) {
        if (['assay','site','effect'].indexOf(key) >= 0) {
            if (!(obj.results && obj.results.constructor == Array && obj.results.length > 0)) {
                obj.results = [{}];
            }
            return this.mSearchService.getTermsFromObject(obj.results[0],key);
        } else if (['test-call','study-call'].indexOf(key) >= 0) {
            if (!(obj.calls && obj.calls.constructor == Array && obj.calls.length > 0)) {
                obj.calls = [{}];
            }
            return this.mSearchService.getTermsFromObject(obj.calls[0],key);
        } else {
            return this.mSearchService.getTermsFromObject(obj,key);
        }
    }

    private updateNodeMetas() {
        var mapping:any = {};
        this.mSelections.forEach((item: CtSearchSelection, index: number, array: CtSearchSelection[]) => {
            var mappeditem = {
                instance: item,
                meta: {
                    url: `/chemtunes/terms/${item.ident}/`,
                    terms: [],
                    title: 'study.'+item.ident,
                    result: [],
                    //holder: this.mSearchService.getTermsFromObject(this.mStudy,item.ident),
                    holder: this.getTermsFromObject(this.mStudy,item.ident),
                    startfrom: false
                },
                parent: this
            };
            mapping[item.ident] = mappeditem;
        });

        //console.log("mapping",mapping);

        this.mSelections.forEach((item: CtSearchSelection, index: number, array: CtSearchSelection[]) => {
            var q = this.mQParams[item.ident];
            var m = mapping[item.ident];
            var p = null;
            m.meta.startfrom = q.start_from;
            if (q.depends && q.depends.length > 0) {
                if (q.depends.length == 1) {
                    p = q.depends[0];
                } else if (q.depends.length > 1) {
                    var ps = this.longestPath(q.depends);
                    p = ps[0];
                }
            }
            if (p != null && mapping[p]) {
                m.parent = mapping[p].instance;
                mapping[p].meta.propagate = p;
            }
        });

        this.mSelections.forEach((item: CtSearchSelection, index: number, array: CtSearchSelection[]) => {
            var m = mapping[item.ident];
            //console.log(m);
            item.setParentNode(m.parent);
            item.setNodeMeta(m.meta);
        });

        super.validateNode(this.mParams);
    }

    public validateNode(params:CtSearchNodeParams) {
        //console.log("CtSearchToxModalController::validateNode",params);
        this.mParams = params;
        if (this.mStudyQueryParametersChange) {
            //console.log("this.mStudyQueryParametersChange -> unsubscribing");
            this.mStudyQueryParametersChange.unsubscribe();
        }
        this.mStudyQueryParametersChange = this.mSearchService.getTerms('/chemtunes/terms/study-query-parameters/', params,(qparams)=>{
            //console.log(qparams);
            if (this.keysEqual(qparams,this.mQParams)) {
                this.updateNodeMetas();
            } else {
                this.mQParams = qparams;
            }
        });
    }

    public invalidateNode() {
        super.invalidateNode();
    }

    ngOnDestroy() {
        this.onDestroy()
    }

}
