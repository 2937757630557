/**
 * Created by joerg on 7/24/17.
 */


"use strict";

// rxjs
import 'rxjs/Rx';
import {Subject, BehaviorSubject, Subscription, Observable,AnonymousSubject} from 'rxjs/Rx';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/operator/distinctUntilChanged';

// angular2
import {forwardRef, Directive, Input, ElementRef, Renderer, ContentChildren, QueryList, Injectable, Injector, SkipSelf, NgZone} from "@angular/core";

// mn
import {MnBackend, MnBrandService, MnDeepMerge, MnLazy} from "@mn/core"

let query = {
    databases: ["ChemTunes"],
    chem: {
        active: true,
        datasetid: "",
        terms: [],
        mode: "structure",
        structure: {
            key: "exact",
            value: 50
        },
        term: {
            key: "exact"
        }
    },
    reg: {
        active: true,
        /*"compound/annotation/inventory"   : {"standard": [], "negate": false},
        "compound/annotation/use type"    : {"standard": [], "negate": false},
        "compound/annotation/use function": {"standard": [], "negate": false}*/
    },
    tox: {
        active: true,
        study : []
    }
};

@Injectable()
export class CtSearchService {

    public Query = query;

    private mChemTermsChange:Subject< string[] > =  new Subject();

    public onChemTermsChange(next?: (value: string[]) => void, error?: (error: any) => void, complete?: () => void):Subscription {
        return this.mChemTermsChange.subscribe(next,error,complete);
    }

    public setChemTerms(chem_terms:string[]) {
        this.Query.chem.terms = chem_terms;
        this.mChemTermsChange.next(chem_terms);
    }

    public reset() {
        query.databases = ["ChemTunes"];
        query.chem = {
            active: true,
                datasetid: "",
                terms: [],
                mode: "structure",
                structure: {
                key: "partial",
                    value: 50
            },
            term: {
                key: "partial"
            }
        };
        query.reg = {
            active: true,
        };
        query.tox = {
            active: true,
            study : []
        }
    }

    constructor(private mBrands:MnBrandService, private mBackend:MnBackend) {
        /*if (mn_help_instance !== null) {
            throw new Error("Multiple instanciation error of MnHelp");
        }
        mn_help_instance = this;
        help_topics = MnBrandService.raw().help.topics;
        console.log("MnHelp created");*/
    }

    public theme():any {
        return "ct-search-theme-default";
        //return MnBrandService.raw().help.theme;
    }


    public getDatabases(next?: (value: any) => void, error?: (error: any) => void, complete?: () => void):Subscription {
        return this.mBackend.get('/chemtunes/dir/databases/').map(res => res.json()).subscribe(next,error,complete);
    }

    public getSearchRows(url:string,next?: (value: any) => void, error?: (error: any) => void, complete?: () => void):Subscription {
        return this.mBackend.get(url).map(res => res.json()).subscribe(next,error,complete);
    }

    public getTerms(url:string, params:any, next?: (value: any) => void, error?: (error: any) => void, complete?: () => void):Subscription {
        //console.log(Object.keys(params).map(k => (k + '=' + params[k].join(':')) ));
        //console.log(Object.keys(params).map(k => (k + '=' + params[k].join(':')) ).join('&'));
        let url_params = url + '?' + Object.keys(params).map(k => (k + '=' + params[k].join(':')) ).join('&');
        //console.log(url_params);
        return this.mBackend.get(url_params).map(res => res.json()).subscribe(next,error,complete);
    }

    public getTermsStartFrom(url:string, params:any, startfrom:string[], next?: (value: any) => void, error?: (error: any) => void, complete?: () => void):Subscription {
        //console.log(Object.keys(params).map(k => (k + '=' + params[k].join(':')) ));
        //console.log(Object.keys(params).map(k => (k + '=' + params[k].join(':')) ).join('&'));
        let url_params = url + '?' + Object.keys(params).map(k => (k + '=' + params[k].join(':')) ).join('&') + '&start_from=' + startfrom.join(':');
        //console.log(url_params);
        return this.mBackend.get(url_params).map(res => res.json()).subscribe(next,error,complete);
    }

    public getTermsFromObject(obj,key) {
        if (obj[key]) return obj[key];
        obj[key] = {standard: [], negate: false};
        return obj[key];
    }

    private emptyStudyRecurse(obj):boolean {
        for (var key in obj) {
            if(!obj.hasOwnProperty(key)) continue;
            let v = obj[key];
            if (v.constructor == Array) {
                for (var i = 0, l = v.length; i < l; i++) {
                    if (this.emptyStudyRecurse(v[i]) == false) {
                        return false;
                    }
                }
            } else {
                if (v.standard && v.standard.length > 0) {
                    return false;
                }
            }
        }
        return true;
    }

    public isSearchActive():boolean {
        let chem_empty =
            (this.Query.chem.active == false) ||
            (this.Query.chem.mode == 'term' && this.Query.chem.terms.length == 0) ||
            (this.Query.chem.mode == 'structure' && this.Query.chem.datasetid == "");
        let reg_empty =
            (this.Query.reg.active == false) ||
            (this.emptyStudyRecurse(this.Query.reg));
        let tox_empty =
            (this.Query.tox.active == false) ||
            (this.Query.tox.study.length == 0);
        return chem_empty && reg_empty && tox_empty;
    }

    private emptyRemove(obj) {
        for (var key in obj) {
            if(!obj.hasOwnProperty(key)) continue;
            let v = obj[key];
            if (v.constructor == Array) {
                for (var i = 0, l = v.length; i < l; i++) {
                    if (this.emptyRemove(v[i]) == false) {
                        return false;
                    }
                }
            } else {
                if (v.standard && v.standard.length == 0) {
                    delete obj[key];
                }
            }
        }
    }

    public getQuery():any {
         let query = this.Query;
        this.emptyRemove(query.reg);
        for (var i = 0, l = query.tox.study.length; i < l; i++) {
            this.emptyRemove(query.tox.study[i]);
        }
        return query;
    }

}