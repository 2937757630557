/**
 * Created by joerg on 7/17/17.
 */

// angular
import {Component,ViewChild} from '@angular/core';

//
import {CtSearchService} from './CtSearchService';
import {CtSearchNode,CtSearchNodeMeta,CtSearchNodeParams} from './CtSearchNode';
import {CtSearchSelectionSiblings} from "./CtSearchSelectionSiblings";

@Component({
    selector: 'ct-search-reg',
    template: '<ct-search-selection-siblings #regulatory_selection></ct-search-selection-siblings>\n',
    styles: [
        `
            /*:host:hover {
                color: red;
            }*/
        `
    ]
})
export class CtSearchReg extends CtSearchNode{

    mRegInterfaces = [];

    @ViewChild('regulatory_selection') private mRegulatorySelection:CtSearchSelectionSiblings;

    constructor(private mSearchService:CtSearchService) {
        super();
    }

    ngAfterViewInit() {
        this.mRegulatorySelection.setParentNode(this);
    }

    public validateNode(params:CtSearchNodeParams) {
        console.log("overwritten invalidate",params);
        this.mParams = params;
        var metas = [];
        console.log("Invalidate CtSearch");
        this.mSearchService.getTerms('/chemtunes/terms/reg/attribute/', params,(rows)=>{
            var metas = rows.map((r)=>{
                return {
                    url: `/chemtunes/terms/reg/attribute/${r.domain}/${r.context}/${r.name}/`,
                    title: 'reg.'+r.name.toLowerCase().replace(' ','_'),
                    result: [],
                    holder: this.mSearchService.getTermsFromObject(this.mSearchService.Query.reg,`${r.domain}/${r.context}/${r.name}`)
                }
            });
            this.mRegulatorySelection.setNodeMetas(metas);
            super.validateNode(this.mParams);
        });
    }

    public invalidateNode() {
        super.invalidateNode();
        this.mRegulatorySelection.setNodeMetas([]);
    }


}
