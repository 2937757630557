import {IVirtualScrollContainer, IVirtualScrollMeasurement, IVirtualScrollOptions, IVirtualScrollWindow} from './basic';

let sbw = 0;

function getScrollBarWidthTest () {
    var inner = document.createElement('p');
    inner.style.width = "100%";
    inner.style.height = "200px";

    var outer = document.createElement('div');
    outer.style.position = "absolute";
    outer.style.top = "0px";
    outer.style.left = "0px";
    outer.style.visibility = "hidden";
    outer.style.width = "200px";
    outer.style.height = "150px";
    outer.style.overflow = "hidden";
    outer.appendChild (inner);

    document.body.appendChild (outer);
    var w1 = inner.offsetWidth;
    outer.style.overflow = 'scroll';
    var w2 = inner.offsetWidth;
    if (w1 == w2) w2 = outer.clientWidth;

    document.body.removeChild (outer);

    return (w1 - w2);
};

function getScrollBarWidth () {
  if (sbw == 0) {
    sbw = getScrollBarWidthTest();
  }
  return sbw;
}

export function calcMeasure(rect: IVirtualScrollContainer, options: IVirtualScrollOptions): IVirtualScrollMeasurement {
  const numPossibleRows = Math.ceil(rect.height / options.itemHeight);
  const numPossibleColumns = options.itemWidth !== undefined ? Math.floor((rect.width - getScrollBarWidth())  / options.itemWidth) : 0;

  return {
    containerHeight: rect.height,
    containerWidth: rect.width,
    itemHeight: options.itemHeight,
    itemWidth: options.itemWidth,
    numPossibleColumns,
    numPossibleItems: numPossibleRows * numPossibleColumns,
    numPossibleRows,
  };
}

const clamp = (min: number, max: number, value: number) => Math.min(max, Math.max(min, value));

export function calcScrollWindow(scrollTop: number, measure: IVirtualScrollMeasurement, numItems: number, dataTimestamp: number, options: IVirtualScrollOptions): IVirtualScrollWindow {
  const numVirtualItems = numItems;

  const requestedColumns = options.numLimitColumns !== undefined ? options.numLimitColumns : measure.numPossibleColumns;
  const numActualColumns = Math.min(numVirtualItems, requestedColumns);

  const numVirtualRows = Math.ceil(numVirtualItems / Math.max(1, numActualColumns));
  const virtualHeight = numVirtualRows * measure.itemHeight;
  const numAdditionalRows = options.numAdditionalRows !== undefined ? options.numAdditionalRows : 1;
  const requestedRows = measure.numPossibleRows + numAdditionalRows;
  const numActualRows = numActualColumns > 0 ? Math.min(requestedRows, numVirtualRows) : 0;

  const actualHeight = numActualRows * measure.itemHeight;

  const visibleEndRow = numActualColumns > 0 && numActualRows > 0 ? clamp(0, numVirtualRows - 1, Math.floor((scrollTop + actualHeight) / measure.itemHeight) - 1) : -1;

  return {
    actualHeight,
    containerHeight: measure.containerHeight,
    containerWidth: measure.containerWidth,
    dataTimestamp,
    itemHeight: measure.itemHeight,
    itemWidth: measure.itemWidth,
    numActualColumns,
    numActualItems: Math.min(numActualRows * numActualColumns, numVirtualItems),
    numActualRows,
    numAdditionalRows,
    numVirtualItems,
    numVirtualRows,
    scrollPercentage: clamp(0, 100, scrollTop / (virtualHeight - measure.containerHeight)),
    scrollTop,
    virtualHeight,
    visibleEndRow,
    visibleStartRow: visibleEndRow !== -1 ? Math.max(0, visibleEndRow - numActualRows + 1) : -1
  };
}

export function getMaxIndex(scrollWin: IVirtualScrollWindow) {
  return scrollWin.visibleEndRow * scrollWin.numActualColumns + scrollWin.numActualColumns - 1;
}
