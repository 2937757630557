/**
 * Created by joerg on 7/17/17.
 */

// angular
import {Component} from '@angular/core';

//
import {CtSearchService} from './CtSearchService';

@Component({
    selector: 'ct-search-splitter',
    templateUrl: './CtSearchSplitter.html',
    styles: [
        `
            :host {
                height: 100%;
                width: 100%;
            }
        `
    ]
})
export class CtSearchSplitter {

    constructor(private mSearch:CtSearchService) {}
}
