/**
 * Created by Joerg on 08.08.2017.
 */

// rxjs
import 'rxjs/add/operator/mergeMap';
import { Observable } from "rxjs/Observable";

// angular
import {Injectable} from '@angular/core';

// mn
import {MnBackend} from '@mn/core'

import {MnDataset} from './MnDataset'

@Injectable()
export class MnDatasetFactory {

    constructor(private mBackend: MnBackend) {}

    public createQueryFromTerms(terms: Array<string>, match: string):any {
        return {
            databases: [
                'ChemTunes'
            ],
            chem: {
                active: true,
                datasetid: '',
                terms: terms.join('\n'),
                mode: 'term',
                structure: {
                    key: 'exact'
                },
                term: {
                    key: match
                }
            },
            reg: {},
            tox: {}
        };
    }

    public createQueryFromStructure(mDatasetid:string, key: string, value):any {
        return {
            databases: [
                'ChemTunes'
            ],
            chem: {
                active: true,
                datasetid: mDatasetid,
                terms: '',
                mode: 'structure',
                structure: {
                    key: key,
                    value: value
                },
                term: {
                    key: 'exact'
                }
            },
            reg: {},
            tox: {}
        };
    }

    /*public createDatasetIdFromQuery(query:any):Observable<string> {
        var dsid = "";
        return this.mBackend.post('/datasets/dataset/',{})
            .map(res => res.json())
            .flatMap((dataset) => {
                dsid = dataset.id;
                dataset.query = JSON.stringify(query);
                return this.mBackend.put('/chemtunes/dataset/'+dataset.id+'/',dataset)
            })
            .map(res => res.json())
            .flatMap((dataset) => this.mBackend.put('/chemtunes/dataset/'+dataset.id+'/execute/',{}) )
            .map(res => res.json())
            .map(data => dsid )
    }*/

    public loadDataset(dsid:string):MnDataset {
        return new MnDataset(this.mBackend,dsid);
    }

    /*public createDatasetFromQuery() {
        var result = new MnDataset();
        let query = {
            chemistry: {
                databases: [
                    {
                        alias: 'ct_chem',
                        active: true,
                        valid: true
                    },
                ],
                active: true,
                datasetid: '',
                terms: terms.join('\n'),
                mode: 'term',
                structure: {
                    key: 'exact'
                },
                term: {
                    key: match
                }
            },
            reg: {},
            tox: {}
        };
        this.mBackend.post('/chemtunes/dataset/',{})
            .map(res => res.json())
            .flatMap((dataset) => {
                dataset.query = JSON.stringify(query);
                return this.mBackend.put('/chemtunes/dataset/'+dataset.id+'/',dataset)
            })
            .map(res => res.json())
            .flatMap((dataset) => {
                return this.mBackend.put('/chemtunes/dataset/'+dataset.id+'/execute/',{})
            })
            .subscribe(
                (data) => {
                    result.mData = data;
                },
                (error) => {
                    console.log("dataset createion error",error);
                },
                () => console.log('Authentication Request Complete')
            );
        return result;

    };*/

}

