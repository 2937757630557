
// rxjs
import 'rxjs/Rx';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subscription } from 'rxjs/Subscription';

import {MnBackend} from "@mn/core";

export class MnDataset {

    private mStatus:any = {
        state: "INITIAL",
        message: "",
        locked: true,
        operation: ""
    };
    private mStatusChange:BehaviorSubject<any>;

    private mPending:Subscription = null;

    public mRaw:any = null;

    constructor(private mBackend: MnBackend, private mId:string) {
        this.mStatusChange = new BehaviorSubject(this.mStatus);
        this.load();
    }

    private load() {
        this.setStatus("LOADING", "load", "", true);
        if (this.mPending) {
            this.mPending.unsubscribe();
        }
        this.mPending = this.mBackend.get(`/datasets/dataset/${this.mId}/?format=json`)
            .map(res => res.json())
            .subscribe(raw => {
                    console.log(JSON.stringify(raw,null,2));
                    console.log(raw);
                    this.mRaw = raw;
                    this.setStatus("READY", "load", "", false);
                    this.mPending = null;
                },
                error => {
                    console.log(error);
                    this.setStatus("FAILURE", "load", "unknown error", true);
                    this.mPending = null;
                },
                () => {
                    this.mPending = null;
                })
    }

    private setStatus(state:string, operation:string, message:string, locked:boolean) {
        this.mStatus = {
            state: state,
            operation: operation,
            message: message,
            locked: locked
        };
        this.mStatusChange.next(this.mStatus);
    }

    public id() {
        return this.mId;
    }

    public raw() {
        return this.mRaw;
    }

    public rows() {
        return this.mRaw.records;
    }

    public cols() {
        return this.mRaw.records_col_spec;
    }

    public delete(record_ids?:Array<string>) {

    }

    public append(structure:any):Observable<any> {
        return this.mBackend.post('/datasets/dataset/' + this.mId + '/add/',structure).map(
            (data) => {
                this.load();
            }
        );
    }

    public insert() {

    }

    public clone() {

    }

    public subscribe(callback):Subscription {
        return this.mStatusChange.subscribe(callback);
    }

    public status():any {
        return this.mStatus;
    }

    private cancel() {
        if (this.mPending !== null) {
            this.mPending.unsubscribe();
            this.mPending = null;
        }
    }

    public destroy() {
        this.cancel();
        this.mRaw = null;
        this.setStatus("DESTROYED","", "", true );
    }


}
