/**
 * Created by Joerg on 05.10.2017.
 */

/**
 * Created by joerg on 7/17/17.
 */

// rxjs
import 'rxjs/Rx';
import {Subscription,Subject,BehaviorSubject} from 'rxjs/Rx';


// angular
import {Component,ViewChildren,QueryList} from '@angular/core';

// mn
import {MnUnsubscribe} from "@mn/core"

//
import {CtSearchService} from './CtSearchService';
import {CtSearchNode,CtSearchNodeMeta,CtSearchNodeParams} from './CtSearchNode';
import {CtSearchSelection} from './CtSearchSelection';





@Component({
    selector: 'ct-search-selection-siblings',
    templateUrl: './CtSearchSelectionSiblings.html'
})
@MnUnsubscribe()
export class CtSearchSelectionSiblings extends CtSearchNode{

    private mMetas:CtSearchNodeMeta[];
    private mSiblingsChange:Subscription;

    @ViewChildren('sibling_selection') private mSiblings:QueryList<CtSearchSelection>;

    constructor(private mSearchService:CtSearchService/*, private mSelectionService:CtSearchSelectionService*/) {
        super();
    }

    ngAfterViewInit() {
        this.mSiblingsChange = this.mSiblings.changes.subscribe(() => this.onSiblingsChange());
    }

    public setNodeMetas(metas:CtSearchNodeMeta[]) {
        this.mMetas = metas;
    }

    private onSiblingsChange() {
        this.mSiblings.forEach((item: CtSearchSelection, index: number, array: CtSearchSelection[]) => {
            item.setParentNode(this);
            item.setNodeMeta(this.mMetas[index]);
        });
        if (this.mValid) {
            super.validateChildNodes(this.mParams);
        }
    }

    /*public validateNode(params:CtSearchNodeParams) {
        console.log("overwritten invalidate");
        this.mParams = params;
        this.mSearchService.getTerms(this.mMeta.url, this.mParams,(rows) => this.onRows(rows))
    }

    public invalidateNode() {
        super.invalidateNode();
        this.mData = this.dummy();
        this.mSelectionService.update(this.mData);
    }*/

    ngOnDestroy() {
        this.onDestroy();
    }
}
