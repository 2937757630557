/**
 * Created by joerg on 7/17/17.
 */

// rxjs
import 'rxjs/Rx';
import {Subscription} from 'rxjs/Rx';

// angular
import {Component,Optional,ElementRef} from '@angular/core';

// aggrid
import {GridOptions,GridApi,ColumnApi,ColDef,RowNode,RowClickedEvent,GroupCellRenderer,/*GroupCellRendererParams,*/RefSelector,IFilterComp,TextFilter} from 'ag-grid/main'

// mn
import {MnLayoutHeight, MnLayoutWidth, MnUnsubscribe, MnTextService} from "@mn/core"

//
import {CtSearchService} from './CtSearchService';
import {CtSearchSelectionService, CtSearchSelectionData} from './CtSearchSelection';
import {CtSearchSelectionListHeader} from './CtSearchSelectionListHeader'

export class MyGroupCellRenderer extends GroupCellRenderer {

    @RefSelector('eExpanded') private eExpanded2: HTMLElement;
    @RefSelector('eContracted') private eContracted2: HTMLElement;

    constructor() {
        super();
    }

    public init(params: /*GroupCellRendererParams*/any): void {

        super.init(params);
        this.addDestroyableEventListener(this.eExpanded2, 'click', this.onExpandClicked2.bind(this));
        this.addDestroyableEventListener(this.eContracted2, 'click', this.onExpandClicked2.bind(this));
    }

    public onExpandClicked2($event): void {
        event.stopPropagation();
    }
}


@Component({
    selector: 'ct-search-selection-list',
    templateUrl: './CtSearchSelectionList.html'
})
@MnUnsubscribe()
export class CtSearchSelectionList {

    private mBlock:boolean = false;
    private mTree:boolean = false;
    private mCols:ColDef[] = [];
    private mRows:string[] = [];
    private mSelectionFiltered:number = 0;
    private mSelection:string[] = [];
    private mReady:boolean = false;
    private mOnWidthChange:Subscription;
    private mOnHeightChange:Subscription;
    private mOnUpdate:Subscription;
    private mOnBlock:Subscription;
    private mOnSelectionStartFrom:Subscription;
    private mOnLanguage:Subscription;
    private mFilterHighlight:RegExp = null;

    private mData:CtSearchSelectionData;

    private mGrid:any /*GridOptions*/ = {
        floatingFilter:true,
        rowData: null,
        enableFilter: true,
        enableSorting: true,
        suppressMenu: true,
        rowSelection: 'multiple',
        groupSelectsChildren: true,
        groupSelectsFiltered: true,
        //checkboxSelection: true,
        suppressRowClickSelection: true,
        onGridSizeChanged: () => { this.sizeColumnsToFit() },
        onGridReady: () => { this.mReady = true; },
        onSelectionChanged: () => { this.onSelectionChanged() },
        onFilterChanged: () => { this.onFilterChanged() },
        onRowDataChanged: () => { this.onRowDataChanged() },
        getNodeChildDetails: (row_item) => {
            if (row_item.nodes && row_item.nodes.length > 0) {
                return {
                    group: true,
                    // open C be default
                    expanded: false,
                    // provide ag-Grid with the children of this group
                    children: row_item.nodes,
                    // the key is used by the default group cellRenderer
                    //key: row_item.label
                    //field: row_item.label,
                    //key: "bla"
                };
            } else {
                return null;
            }
        },
        onRowClicked: (event) => { this.onRowClick(event) },
        /*onRowDoubleClicked(params) {
            console.log("onRowDoubleClickedClicked(params)",params);
        },*/
    };

    constructor( private mSelectionService:CtSearchSelectionService,
                 @Optional() private mHeight:MnLayoutHeight,
                 @Optional() private mWidth:MnLayoutWidth,
                 private mSearchService:CtSearchService,
                 private mTextService:MnTextService,
                 private mElRef:ElementRef,
    ) {
        if (mHeight) { this.mOnHeightChange = mHeight.onChange(() => this.updateLayout()); }
        if (mWidth) { this.mOnWidthChange = mWidth.onChange(() => this.updateLayout()); }
        this.mOnUpdate = mSelectionService.onUpdate((data) => this.update(data));
        this.mOnBlock = mSelectionService.onBlock((block) => this.block(block));
        this.mOnSelectionStartFrom = mSelectionService.onSelectionStartFrom((s) => this.onSelectionStartFrom(s));
        this.mOnLanguage = this.mTextService.onLanguageChange((language)=> this.updateLanguage(language));
    }

    private updateLanguage(language) {
        if (this.mData) this.updateColumn();
    }

    private block(block:boolean) {
        this.mBlock = block;
    }

    private update(data:CtSearchSelectionData) {
        if (data == null) return;
        this.mData = data;
        this.mRows = data.rows;
        this.mSelection = data.selection;
        this.mTree = data.tree;
        this.updateColumn();
    }

    private updateColumn() {
        if (this.mCols.length > 0) {
            this.mCols[0].headerComponentParams.object.instance = null;
        }
        //let lang:string = this.mTextService.language();
        if (this.mTree) {
            this.mCols = [{
                headerName: <string>this.mTextService.text(this.mData.title),
                filter: "text",
                suppressMenu:true,
                field: 'label',
                //valueFormatter: (params) => { return params.value.replace(this.mFilterHighlight, (str) => '<span class="filter-highleight">'+str+'</span>'); },
                cellRenderer: MyGroupCellRenderer,
                cellRendererParams: {
                    /*checkbox: function(params) {
                        return params.node.group === true;
                    },*/
                    //padding: 20,
                    //innerRenderer: function(params) { return params.value+2; }
                    innerRenderer: (params) => {
                        return this.mFilterHighlight == null ? params.value : params.value.replace(this.mFilterHighlight, (str) => '<span class="filter-highleight">'+str+'</span>');
                    }
                },
                //valueGetter: (params) => { return params.data; },
                headerCheckboxSelection: true,
                headerCheckboxSelectionFilteredOnly: true,
                headerComponent: CtSearchSelectionListHeader,
                headerComponentParams: {
                    tree: true,
                    object: { instance: null }
                }
                //checkboxSelection: true
            }]
        } else {
            this.mCols = [{
                headerName: <string>this.mTextService.text(this.mData.title),
                filter: "text",
                suppressMenu:true,
                field: "label",
                valueGetter: (params) => { return params.data; },
                valueFormatter: (params) => { return this.mFilterHighlight == null ? params.value : params.value.replace(this.mFilterHighlight, (str) => '<span class="filter-highleight">'+str+'</span>'); },
                headerCheckboxSelection: true,
                headerCheckboxSelectionFilteredOnly: true,
                headerComponent: CtSearchSelectionListHeader,
                headerComponentParams: {
                    tree: false,
                    object: { instance: null }
                }
                //checkboxSelection: true
            }]
        }
    }

    private updateLayout() {
        if (this.mReady) {
            this.mGrid.api.doLayout();
        }
    }

    private updateSelection() {
        if (this.mTree) {
            this.mGrid.api.forEachNode((node:RowNode) => {
                //console.log(node);
                if (node.data.value) {
                    node.setSelected(this.mSelection.indexOf(node.data.value) >= 0,false, true)
                } else {
                    if (node.data.nodes) {
                        let allchidrenselected:boolean = true;
                        for (var i = 0, l = node.data.nodes.length; i < l; i++) {
                            if (this.mSelection.indexOf(node.data.nodes[i].value) < 0) {
                                allchidrenselected = false;
                                break;
                            }
                        }
                        node.setSelected(allchidrenselected,false, true)
                    }
                }
            });
        } else {
            this.mGrid.api.forEachNode((node:RowNode) => {
                node.setSelected(this.mSelection.indexOf(node.data) >= 0,false, true)
            });
        }
    }

    private onSelectionStartFrom(s) {
        this.mSelection = s;
        this.updateSelection();
        this.onFilterChanged();
    }

    private onRowClick(event?: RowClickedEvent) {
        // change the current selection
        let ee:any = <any>event.event;
        let shiftKeyPressed = ee.shiftKey;
        if (event.node.isSelected()) {
            event.node.setSelectedParams({
                newValue: false,
                clearSelection: false,
                tailingNodeInSequence: false,
                rangeSelect: shiftKeyPressed
            });
        } else {
            event.node.setSelectedParams({
                newValue: true,
                clearSelection: false,
                tailingNodeInSequence: false,
                rangeSelect: shiftKeyPressed
            });
        }
    }

    private sizeColumnsToFit() {
        if (this.mGrid && this.mGrid.api && this.mElRef.nativeElement.clientWidth > 0) {
            this.mGrid.api.sizeColumnsToFit();
        }
    }

    private onRowDataChanged() {
        //console.log("onRowDataChanged()");
        if (this.mGrid.api) {
            this.mGrid.api.setFilterModel(null);
            this.sizeColumnsToFit();
            this.mGrid.api.hideOverlay();
            this.mSelectionFiltered = 0;
            this.updateSelection();
        }
    }

    private onSelectionChanged() {
        //console.log("SEL:XXX");
        // collect the current selection
        let new_sel = this.mGrid.api.getSelectedRows();
        if (this.mTree) {
            let new_sel_fix = [];
            this.collectSelection(new_sel,new_sel_fix);
            //console.log(new_sel);
            new_sel = new_sel_fix;
        }
        this.mSelectionService.selection(new_sel);
        this.mSelection = new_sel;

        this.onFilterChanged();
    }

    private collectSelection(tree:any[],selection:any[]) {
        for (var i = 0, l = tree.length; i < l; i++) {
            var n = tree[i];
            if (n.value && n.value != null) {
                selection.push(n.value);
            }
            if (n.nodes && n.nodes.length > 0) {
                this.collectSelection(n.nodes,selection);
            }
        }
    }

    private collectFilteredSelection(tree:any[],s:any) {
        for (var i = 0; i<tree.length; i++) {
            var rowNode = tree[i];
            //console.log(rowNode);
            if (rowNode.isSelected()) s.s++;
            if (rowNode.childrenAfterFilter && rowNode.childrenAfterFilter.length > 0) {
                this.collectFilteredSelection(rowNode.childrenAfterFilter,s);
            }
        }
    }

    private onFilterChanged() {
        if (this.mGrid.api && this.mCols.length > 0) {
            let fc:IFilterComp = this.mGrid.api.getFilterInstance("label");
            if (fc) {
                let ft:TextFilter= <TextFilter> fc;
                let fs:string = ft.getFilter();
                if (fs && fs.length > 0) {
                    this.mFilterHighlight = new RegExp(fs, 'gi');
                } else {
                    this.mFilterHighlight = null;
                }
                //console.log("AA",fc);
            } else {
                this.mFilterHighlight = null;
            }
            this.mGrid.api.redrawRows();
        }
        var s = { s: 0 };
        if (this.mGrid.api && this.mGrid.api.getModel && this.mGrid.api.getModel()) {
            var model = this.mGrid.api.getModel();
            var rowCount = model.getRowCount();
            for (var i = 0; i<rowCount; i++) {
                var rowNode = model.getRow(i);
                //console.log(rowNode);
                if (rowNode.isSelected()) s.s++;
                if (rowNode.childrenAfterFilter && rowNode.childrenAfterFilter.length > 0) {
                    this.collectFilteredSelection(rowNode.childrenAfterFilter,s);
                }
            }
        }
        this.mSelectionFiltered = s.s;
        if (this.mCols.length > 0 && this.mCols[0].headerComponentParams.object.instance) {
            this.mCols[0].headerComponentParams.object.instance.updateSelection({
                total: this.mSelection.length,
                filtered: this.mSelection.length - this.mSelectionFiltered
            });
        }
    }

    ngOnDestroy() {
        if (this.mCols.length > 0) {
            this.mCols[0].headerComponentParams.object.instance = null;
        }
    }



}












