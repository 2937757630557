/**
 * Created by joerg on 7/17/17.
 */

// angular
import {Component,Input,HostBinding} from '@angular/core';

// mn
import {MnDeepMerge} from "@mn/core";

//
import {CtSearchService} from './CtSearchService';
import {CtSearchNode} from './CtSearchNode';
import {CtSearchToxModal,CtSearchToxModalService} from "./CtSearchToxModal";

@Component({
    selector: '[ct-search-tox-group]',
    template: `
        <div class="ct-search-tox-study-header-sub" *ngIf="mKeys.length > 0" [mn-text]="label"></div>
        <div class="ct-search-tox-study-content" *ngFor="let k of mKeys;">
            <span class="ct-search-tox-study-label"><span [mn-text]="'study.'+k"></span> {{'(' + study[k].standard.length + ')'}}:</span> {{study[k].standard.join(", ")}}
        </div>
    `,
    /*styleUrls: ["./CtSearchToxGroup.css"]*/
})
export class CtSearchToxGroup {
    private mKeys:string[] = [];
    constructor() {}
    @Input() study;
    @Input() label;
    @Input() set keys(values:string[]) {
        this.mKeys = values.filter((key)=>{
            return this.study[key] && this.study[key].standard && this.study[key].standard.length > 0;
        })
    }
    @HostBinding('class.ct-search-tox-study-group') someField: boolean = true;
}

@Component({
    selector: 'ct-search-tox',
    templateUrl: './CtSearchTox.html',
    styles: [
        `
    .cell-close {
      position: absolute;
      top: 1px;
      right: 0px;
      border: none;
      background: transparent;
    }

            :host {
                position: relative;
            }
            
            /deep/ .mat-radio-container .mat-ripple {
                display: none !important;
            }

            /deep/ .mat-radio-container {
                height: 16px !important;
                width: 16px !important;
            }

            /deep/ .mat-radio-inner-circle {
                height: 16px !important;
                width: 16px !important;
            }

            /deep/ .mat-radio-outer-circle {
                height: 16px !important;
                width: 16px !important;
                border-width: 1px !important;
            }

            /deep/ .mat-radio-label-content {
                padding-left: 6px !important;
            }


            
            .ct-search-tox-add {
                position: absolute;
                top: calc(50% - 20px);
                left: calc(50% - 20px);
            }
            .header {
                 position: absolute;
                 top: 0px;
                 height: 28px;
            }
            .wrapper {
                position: absolute;
                padding: 4px;
                background: transparent;
                overflow-y: auto;
                -webkit-overflow-scrolling: touch;
                display: block;
                width: 100%;
                height: calc(100% - 28px);
                top: 28px;
                font-size: 0px;
            }
            .frame {
                font-size: 12px;
                min-height: 110px;
                width: calc(100% - 8px);
                background: white;
                margin: 2px;
                border: 1px solid darkgray;
                display: block;
                overflow: visible;
                position: relative;
            }
            .ident {
                background: #fbfbfb;
                color: #606060;
                font-weight: bold;
                font-size: 14px;
                padding: 3px 3px;
                /*border-bottom: 1px solid darkgrey;*/
            }
            .frameenabled:hover > .overlay {
				display: block;
			}
            .overlay {
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
                display: none;
                font-size: 14px;
                font-weight: bold;
                color: #aaa;
            }
            .overlay_background {
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
                /*background: #bce1ff;*/
                /*background: #d1eaff;*/
                background: #f6fbff;
                opacity: 0.86;
            }
            .remove {
                font-size: 12px;
                position: absolute;
                top: 3px;
                right: 3px;
            }
            .remove_hint {
                position: absolute;
                top: 3px;
                right: 30px;
                text-align: right;
            }
            .edit {
                line-height: 26px;
                position: absolute;
                left: 3px;
                top: calc(50% - 26px);
            }

            .mybutton {
                 font-size: 12px;
                 margin-left: 4px;
                 background: none;
                 border: none;
            }
            .add {
                position: absolute;
                top: calc(50% - 15px);
                left: calc(50% - 15px);
            }
            .addhint {
                position: absolute;
                top: calc(50% + 25px);
                left: calc(50% - 25px);
				width: 100%;
				left: 0px;
				font-weight: bold;
				text-align: center;
				color: lightgray;
				font-size: 13px;
            }
        	.frame .hover { visibility: hidden; }
			.frame:hover .hover { visibility: visible; }
			.bold {
                font-weight: bold;
			}
			.outer td {
                vertical-align: top;
                padding: 0px;
			}
			.inner td {
                vertical-align: top;
                padding: 2px 4px;
			}
        `
    ]

})
export class CtSearchTox extends CtSearchNode{

    private mDialogStudy:number = -1;

    constructor(private mSearchService:CtSearchService,private mToxModalService:CtSearchToxModalService) {
        super();
    }

    private close(index:number) {
        this.mSearchService.Query.tox.study.splice(index,1);
    }

    private openDialogEdit($event,index:number): void {
        this.mDialogStudy = index;
        let study = MnDeepMerge.merge(this.mSearchService.Query.tox.study[index],{});
        this.mToxModalService.open(this,study).subscribe((result) => { this.onDialogCloseEdit(result); })
    }
    private openDialogNew(): void {
        //let study = MnDeepMerge.merge(this.mSearchService.Query.tox.study[0],{});
        this.mToxModalService.open(this,{}).subscribe((result) => { this.onDialogCloseNew(result); })
    }

    private onDialogCloseEdit(result:any):void {
        console.log("Dialog Result",result);
        if (result === false || result == undefined) return;
        this.mSearchService.Query.tox.study[this.mDialogStudy] = result;
        this.mDialogStudy = -1;
        console.log("Query",this.mSearchService.Query);
    }

    private onDialogCloseNew(result:any):void {
        console.log("Dialog Result",result);
        if (result === false || result == undefined) return;
        this.mSearchService.Query.tox.study.push(result);

        console.log("Query",this.mSearchService.Query);
    }


    /*mRegInterfaces = [];

    @ViewChild('regulatory_selection') private mRegulatorySelection:CtSearchSelectionSiblings;

    constructor(private mSearchService:CtSearchService) {
        super();
    }

    ngAfterViewInit() {
        this.mRegulatorySelection.parent = this;
    }
    public invalidate() {
        console.log("Invalidate CtSearch");
        this.mSearchService.getSearchRows('/chemtunes/reg/attribute/?db=ChemTunes',(rows)=>{
            this.mRegInterfaces = rows.map((r)=>{
                return {
                    url: `/chemtunes/reg/attribute/${r.domain}/${r.context}/${r.name}/?db=ChemTunes`,
                    title: r.name,
                    selection: [],
                }
            });
            this.mRegulatorySelection.setInterfaces(this.mRegInterfaces);
        });
        super.invalidate();
    }*/

}
