/**
 * Created by Jongjin on 09/05/17.
 */

// rxjs
import 'rxjs/Rx';
import { Observable } from 'rxjs/Rx';

// angular
import {Component, ViewChild, Injectable} from '@angular/core';
import { MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material';

// jsme
import {MnJsme} from "@mn/jsme"

@Component({
    selector: 'mn-jsme-dialog',
    template: `<div>
                    <mn-jsme [height]="400"></mn-jsme>
               </div>
               <div class="buttonSet">
                    <button mat-button mat-dialog-close (click)="close()" style="margin-right: 5px;">Cancel</button><button mat-button  (click)="close(true)">Submit</button>
               </div>
    `,
    styles:[`
        .buttonSet{
            margin-top: 10px;
            align-items: right;
            text-align: right;
        }
    `]
})
export class MnJsmeDialog {

    mol:string;

    @ViewChild(MnJsme) public mJsme:MnJsme;

    constructor(public dialogRef: MatDialogRef<MnJsmeDialog>){

    }

    close(isSubmit:boolean){
        if(isSubmit){
            // console.log('mol  ',this.getMol());
            this.dialogRef.close(this.getMol());
        }else{
            this.dialogRef.close();
        }
    }

    getMol(){
        return this.mJsme.mol2000();
    }

    setMol(mol){
        return this.mJsme.setMol(mol);
    }

}


@Injectable()
export class MnJsmeDialogService {

    constructor(public dialog: MatDialog) { }

    public open(): Observable<any> {
        let dialogRef: MatDialogRef<MnJsmeDialog>;
        dialogRef = this.dialog.open(MnJsmeDialog);
        return dialogRef.afterClosed();
    }

    public openExisting(mol:string): Observable<any> {
        let dialogRef: MatDialogRef<MnJsmeDialog>;
        dialogRef = this.dialog.open(MnJsmeDialog);
        setTimeout(() => {
            dialogRef.componentInstance.setMol(mol);
        });
        return dialogRef.afterClosed();
    }

}