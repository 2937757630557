/**
 * Created by Jörg on 29.08.2017.
 */

// rxjs
import {Observable} from 'rxjs/Observable';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Subject} from 'rxjs/Subject';
import {Subscription} from 'rxjs/Subscription';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/range';
import 'rxjs/add/operator/reduce';

// angular
import {Component,Directive,Input,Renderer2,ElementRef,HostBinding,Injectable,Output,EventEmitter} from '@angular/core';

// od-virtualscroll
import {ScrollObservableService,IVirtualScrollOptions,ComponentFactoryResolverService} from './vs/api';

// mn
import {MnWindowSize, MnLayoutWidth, MnBackend, MnUnsubscribe} from "@mn/core";
import {MnDataset} from "./MnDataset";
import {MnAuthService} from "@mn/auth";

//
import {MnJsmeDialogService} from './MnJsmeDialog';


let image_cache_list:string[] = [];
let image_cache_map:any = {};


@Directive({
    selector:'img[mn-dataset-grid-cell-image]'
})
export class MnDatasetGridCellImage {

    mInit:boolean = false;
    mSubscription:any = null;

    mUrl:string = "";
    @Input('url') set url(value) {
        this.mUrl = value;
        if (value.startsWith(this.mBackend.base())) {
            this.mUrl = value.substr(this.mBackend.base().length);
        }
        this.mUrl = this.mUrl.replace("?format=json","");
        if (this.mInit) this.update();
    }

    mSize = {
        height: 150,
        width: 150
    };
    @Input('size') set size(value) {
        this.mSize = value;
        this.update();
    }

    constructor(private mElRef: ElementRef, private mRenderer: Renderer2, private mBackend:MnBackend) {}

    ngOnDestroy() {
        if (this.mSubscription !== null) this.mSubscription.unsubscribe();
    }

    ngAfterViewInit() {
        this.mInit = true;
        this.update();
    }

    update() {
        if (this.mInit == false) return;
        //console.log(this.mUrl);

        let key = this.mUrl + ':' + this.mSize.height + ':' + this.mSize.width;
        if (image_cache_map[key]) {
            this.mRenderer.setAttribute(this.mElRef.nativeElement, 'src', 'data:image/svg+xml;utf8,'+image_cache_map[key] );
            return;
        }

        this.mRenderer.removeAttribute(this.mElRef.nativeElement, 'src');
        if (this.mSubscription) this.mSubscription.unsubscribe();
        this.mSubscription = this.mBackend.get(this.mUrl + '?format=json&height='+this.mSize.height+'&width='+this.mSize.width)
            .map(res => res.json())
            .map(res => res.image_data)
            .subscribe(
                success => {
                    this.mRenderer.setAttribute(this.mElRef.nativeElement, 'src', 'data:image/svg+xml;utf8,'+success );
                    image_cache_map[key] = success;
                    image_cache_list.push(key);
                    if (image_cache_list.length > 100) {
                        var to_be_removed = image_cache_list.shift();
                        delete image_cache_map[to_be_removed];
                    }
                },
                error => console.log("Error loading image: ", error)
            );
    }
}


@Injectable()
export class MnDatasetGridService {
    constructor() {}
    grid:any = null;
}

@Component({
    selector: 'mn-dataset-grid-cell',
    templateUrl: "./MnDatasetGridCell.html",
})
export class MnDatasetGridCell {

    @Input() item;

    constructor(private mGridService:MnDatasetGridService) {}

    private addStructure() {
        this.mGridService.grid.addStructure();
    }
    private edit(item:any) {
        console.log("edit");
        this.mGridService.grid.edit(item);
    }
    private close(item:any) {
        console.log("close");
        this.mGridService.grid.remove(item);
    }
}

@Component({
    selector: 'mn-dataset-grid',
    templateUrl: "./MnDatasetGrid.html",
    styleUrls: ["./MnDatasetGrid.css"],
    providers: [ScrollObservableService,MnDatasetGridService]
})
@MnUnsubscribe()
export class MnDatasetGrid {

    private mSize:any = {
        width: 156,
        height: 156
    };
    private mCellStyle:any;

    @Input()
    get size() { return this.mSize; }
    set size(v: any) {
        this.mSize = v;
        this.updateCellStyle();
        this.updateOptions();
    }

    @Input()
    get datasetid() {
        if (this.mDataset) {
            return this.mDataset.id();
        }
        return "";
    }
    set datasetid(v: any) {
        this.loadDataset(v);
    }
    @Output('datasetidChange') mDatasetidChange = new EventEmitter();

    mData:any[] = [
        {index:1,data:'last'}
    ];
    mDataObservable:BehaviorSubject<any[]>;
    mDataset:MnDataset;
    mOptions:BehaviorSubject<IVirtualScrollOptions>;
    mResize:Subject<any> = new Subject();

    mWindowSizeChange:Subscription;
    mLayoutWidthChange:Subscription;
    mDatasetStatusChanged:Subscription;

    private mDatasetUrl:any;

    @HostBinding('class.mn-dataset-grid') someField: boolean = true;

    constructor(
        private mBackend:MnBackend,
        window_size:MnWindowSize,
        layout_width:MnLayoutWidth,
        private mGridService:MnDatasetGridService,
        private mJsmeDialog:MnJsmeDialogService,
    ) {
        this.mGridService.grid = this;
        this.updateCellStyle();
        this.mOptions = new BehaviorSubject(this.generateOptions());
        this.mWindowSizeChange = window_size.onChange((s) => this.updateLayout());
        this.mLayoutWidthChange = layout_width.onChange((w) => this.updateLayout());
        this.mDataObservable = new BehaviorSubject(this.mData);
    }

    private updateLayout() {
        this.mResize.next();
    }

    private generateOptions():IVirtualScrollOptions {
        return {
            itemWidth: this.mSize.width,
            itemHeight: this.mSize.height,
            numAdditionalRows: 1
        };
    }

    private updateCellStyle() {
        this.mCellStyle = {
            'width.px': this.mSize.width,
            'height.px':this.mSize.height ,
        };
    }

    private updateOptions() {
        this.mOptions.next(this.generateOptions())
    }

    public edit(item:any) {
        console.log(item);

        let url = item.data.url;
        if (url.startsWith(this.mBackend.base())) {
            url = url.substr(this.mBackend.base().length);
        }
        url = url.replace("?format=json","");
        console.log(url);

        this.mBackend.get(url + 'mol/')
            .map(res => res.json())
            .map(res => res.mol)
            .subscribe((mol) => {
                console.log("mol",mol);
                this.mJsmeDialog.openExisting(mol).subscribe(
                    (result) => {
                        console.log("Jsme result:",result);
                        if (result) {
                            //this.mBackend.post('/datasets/dataset/' + this.mDataset + '/add/',{mol: result}).subscribe();
                            this.mBackend.put(url,{mol_file:result}).subscribe(
                                (data) => {
                                    console.log(data);
                                    image_cache_list = [];
                                    image_cache_map = {};
                                    let ds = <any> this.mDataset;
                                    ds.load();
                                }
                            )
                        }
                    }
                )

            })
    }

    public remove(item:any) {
        console.log(item);

        let url = item.data.url;
        if (url.startsWith(this.mBackend.base())) {
            url = url.substr(this.mBackend.base().length);
        }
        url = url.replace("?format=json","");
        console.log(url);

        this.mBackend.delete(url)
            .subscribe(() => {
                let ds = <any> this.mDataset;
                ds.load();
            })
    }

    public addStructure() {
        if (this.mDataset) {
            this.appendCompoundWithEditor();
        } else {
            this.createDataset().subscribe((dsid) => {
                this.loadDataset(dsid).subscribe((dataset) => {
                    console.log(this.mDataset);
                    this.appendCompoundWithEditor();
                 })
            })
        }
    }

    private appendCompoundWithEditor() {
        this.mJsmeDialog.open().subscribe(
            (result) => {
                console.log("Jsme result:",result);
                if (result) {
                    //this.mBackend.post('/datasets/dataset/' + this.mDataset + '/add/',{mol: result}).subscribe();
                    this.mDataset.append({mol: result}).subscribe(
                        (data) => {
                            console.log("Compound added:",data);
                        }
                    )
                }
            }
        )
    }

    private createDataset():Observable<string> {
        return this.mBackend.post('/datasets/dataset/',{context:"chemtunes"}).map(res => res.json()).map((dataset) => {
            this.mDatasetidChange.emit(''+dataset.id);
            return ''+dataset.id;
        })
    }

    private reset() {
        this.mData = [{index:1,data:'last'}];
        this.mDataObservable.next([]);
        this.mDataObservable.next(this.mData);
        if (this.mDatasetStatusChanged) {
            this.mDatasetStatusChanged.unsubscribe();
        }
        this.mDataset = undefined;
    }

    public reload() {
        if (this.mDataset) {
            this.loadDataset(this.mDataset.id());
        }
    }

    private loadDataset(dsid:string):Observable<MnDataset> {
        if (typeof dsid != 'string') return;
        if (dsid.length == 0) {
            this.reset();
            return;
        }
        let dataset:MnDataset = new MnDataset(this.mBackend,dsid);
        let dataset_subject = new Subject<MnDataset>();
        let dataset_subscription = dataset.subscribe((status)=> {
            if (status.state == "READY" /*|| status.state == "IGNORED"*/) {
                dataset_subscription.unsubscribe();
                this.setDataset(dataset);
                dataset_subject.next(dataset);
            }
        });
        return dataset_subject;
    }

    private setDataset(dataset:MnDataset) {
        this.mDataset = dataset;
        if (this.mDatasetStatusChanged) {
            this.mDatasetStatusChanged.unsubscribe();
        }
        if (dataset) {
            this.mDatasetStatusChanged = this.mDataset.subscribe((status) => this.onDatasetStatusChanged(status) );
        }
    }

    private onDatasetStatusChanged(status) {
        console.log("Dataset status:",status,this.mDataset);
        if (status.state == "READY") {
            console.log(this.mDataset);
            let rows = this.mDataset.raw().table.data;
            this.mData = rows.map( (v,i) => {
                return {
                    index: i,
                    data: v
                }
            });
            this.mData.push({data:'last'});
            console.log(this.mData);
            this.mDataObservable.next([]);
            this.mDataObservable.next(this.mData);
        }
    }

    ngOnDestroy() {
        this.mGridService.grid = null;
    }

}
